import React from "react";
import { graphql } from "gatsby";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { GatsbyImage } from "gatsby-plugin-image";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import Hero from "../components/Hero/HeroSplit";
import ValueProps from "../components/Repeating/ValueProps";
import About from "../components/Repeating/About";
import Testimonials from "../components/Repeating/Testimonials";
import Resources from "../components/Repeating/Resources";
import CallToAction from "../components/Repeating/CTA";
import ButtonSolid from "../components/Button/ButtonSolid";
import ButtonUnderline from "../components/Button/ButtonWithUnderline";
import ScrollWrapper from "../components/Scroll/ScrollWrapper";
import ScrollNavigation from "../components/Scroll/ScrollNavigation";
import ScrollContent from "../components/Scroll/ScrollContent";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemPanel,
  AccordionItemState,
} from "react-accessible-accordion";

const Page = ({ data }) => {
  const url = typeof window !== "undefined" ? window.location.pathname : "";

  const faqs = [
    {
      question: "When should I refinance?",
      answer: (
        <>
          <p>
            When mortgage rates drop 1% to 2% lower than your current loan rate,
            it’s an excellent time to consider refinancing. The most significant
            benefit is that your monthly mortgage payment is likely to drop,
            saving you money in your budget.
          </p>
        </>
      ),
    },
    {
      question:
        "Should I refinance if I plan on moving within a couple of years?",
      answer: (
        <>
          <p>
            It’s generally not a good idea to refinance at this point because
            the amount you save each month may not be enough to cover the costs
            of refinancing.
          </p>
        </>
      ),
    },
    {
      question: "How much will it cost me to refinance?",
      answer: (
        <>
          <p>
            Application fees are typically between $250 and $350, plus there may
            be an origination fee that’s usually 1% of your loan and other fees.
            So it’s best to be prepared to pay 2% to 3% of your loan amount.
            However, some lenders do offer no-cost loans with a higher interest
            rate.
          </p>
        </>
      ),
    },
    {
      question: "What are points?",
      answer: (
        <>
          <p>
            You can pay a lender points to get specific terms you'd like in your
            mortgage financing. Each point is 1% of your total loan. For
            example, if your home loan amount were $400,000, each point would
            cost $4,000. Points are typically purchased to get a lower interest
            rate.
          </p>
        </>
      ),
    },
    {
      question: "Should I pay points to lower my interest rate?",
      answer: (
        <>
          <p>
            If you plan to stay in your home for at least a few years, then
            paying for points to lower the interest rate can help you keep your
            monthly loan payment where you want it. Points can also help you
            qualify for a bigger loan amount.
          </p>
          <p>
            If you don’t plan on living in the home for more than a year or two,
            it’s probably best not to consider purchasing points. That’s because
            your monthly savings may not make up the cost you paid upfront.
          </p>
        </>
      ),
    },
    {
      question: "What does it mean to lock the interest rate?",
      answer: (
        <>
          <p>
            Interest rates can change from day to day, and locking in your rate
            keeps you from having to ride the wave. A sharp rise in an interest
            rate can increase a mortgage payment, so lenders can allow a
            borrower to lock in an interest rate. The rate is locked for a
            specific period of time, usually 30 to 60 days, and sometimes there
            is a fee.
          </p>
        </>
      ),
    },
  ];

  return (
    <Layout>
      <SearchEngineOptimization
        title="Refinance in Minnesota | Thistle Financial"
        description="Let Thistle be your guide when you decide to refinance in Minnesota. Lower your interest rate and monthly payment, get a shorter term, or cash out equity."
        // openGraphImage={data.openGraphImage.publicURL}
        // twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />

      <Hero
        image={data.heroDesktop.childImageSharp.gatsbyImageData}
        imageAlignment="right"
        textWidth="1/2"
        imageWidth="1/2"
        minHeight="md:min-h-[626px]"
        padding="pb-16 md:pb-0"
      >
        <h1>Refinance In Minnesota</h1>
        <p>
          Are you considering refinancing your mortgage? There are certainly
          advantages to taking out a new loan and paying off the one you have.
          Let the Thistle team be your guide.
        </p>
        <ButtonSolid href="/request-rates/" text="Request Rates" />
      </Hero>

      <section className="mb-20 md:mb-32 bg-primary-50 py-20 md:py-32">
        <div className="container">
          <header className="max-w-2xl mb-16 md:mb-20">
            <h2>Why Refinance?</h2>
          </header>

          <ScrollWrapper className="grid lg:grid-cols-12 gap-y-16 lg:gap-x-20">
            <ScrollNavigation className="lg:col-start-1 lg:col-span-4 hidden md:block relative after:content after:absolute after:right-0 after:top-0 after:w-px after:h-full after:bg-primary-600/30">
              <li>
                <AnchorLink
                  to={url + "#anchor-1"}
                  title="Lower Your Interest Rate"
                  stripHash
                  className="relative font-body font-bold tracking-widest uppercase"
                />
              </li>
              <li>
                <AnchorLink
                  to={url + "#anchor-2"}
                  title="Lower Your Monthly Payment"
                  stripHash
                  className="relative font-body font-bold tracking-widest uppercase"
                />
              </li>
              <li>
                <AnchorLink
                  to={url + "#anchor-3"}
                  title="Get A Shorter Term"
                  stripHash
                  className="relative font-body font-bold tracking-widest uppercase"
                />
              </li>
              <li>
                <AnchorLink
                  to={url + "#anchor-4"}
                  title="Cash Out Your Equity"
                  stripHash
                  className="relative font-body font-bold tracking-widest uppercase"
                />
              </li>
            </ScrollNavigation>

            <ScrollContent className="lg:col-end-13 lg:col-span-8">
              <div
                id="anchor-1"
                className="bg-white shadow-4xl pt-8 md:pt-10 px-4 md:px-10 pb-4 md:pb-6 mb-10 lg:mb-14"
              >
                <h3 className="mb-1">Lower Your Interest Rate</h3>
                <div className="w-18 h-0.5 bg-primary-600 mb-6"></div>
                <p>
                  When interest rates fall, you might consider refinancing your
                  home to take advantage of a lower interest rate. It might
                  substantially reduce the interest you pay over time.
                </p>
              </div>

              <div
                id="anchor-2"
                className="bg-white shadow-4xl pt-8 md:pt-10 px-4 md:px-10 pb-4 md:pb-6 mb-10 lg:mb-14"
              >
                <h3 className="mb-1">Lower Your Monthly Payment</h3>
                <div className="w-18 h-0.5 bg-primary-600 mb-6"></div>
                <p>
                  Refinancing can create some space in your budget. A lower
                  monthly mortgage payment can be helpful if your finances are
                  getting tight or you're about to experience a significant life
                  change, like having a baby.
                </p>
              </div>

              <div
                id="anchor-3"
                className="bg-white shadow-4xl pt-8 md:pt-10 px-4 md:px-10 pb-4 md:pb-6 mb-10 lg:mb-14"
              >
                <h3 className="mb-1">Get A Shorter Term</h3>
                <div className="w-18 h-0.5 bg-primary-600 mb-6"></div>
                <p>
                  Some homeowners choose to refinance so they can switch from a
                  30-year term to a 15-year term. Depending on the interest
                  rate, the change in your monthly mortgage payment might be
                  minimal.
                </p>
              </div>

              <div
                id="anchor-4"
                className="bg-white shadow-4xl pt-8 md:pt-10 px-4 md:px-10 pb-10 md:pb-12"
              >
                <h3 className="mb-1">Cash Out Your Equity</h3>
                <div className="w-18 h-0.5 bg-primary-600 mb-6"></div>
                <p>
                  Cashing out home equity can help with large expenses, such as
                  sending a child to college or remodeling your home. You can
                  also reinvest it. For example, you may decide to purchase some
                  property.
                </p>
              </div>
            </ScrollContent>
          </ScrollWrapper>
        </div>
      </section>

      <section className="mb-20 md:mb-32">
        <div className="container">
          <div className="grid md:grid-cols-2 gap-y-8 md:gap-x-10 lg:gap-x-20 items-center">
            <div>
              <h2>Calculate your savings</h2>
              <p>
                See just how much you can save on your monthly mortgage by
                refinancing. You might be surprised by how a lower interest rate
                or different term can make a difference.
              </p>
              <ButtonUnderline href="" text="Learn More" />
            </div>
            <div>
              <GatsbyImage
                image={data.calculateSavings.childImageSharp.gatsbyImageData}
              />
            </div>
          </div>
        </div>
      </section>

      <section className="mb-20 md:mb-32">
        <div className="container">
          <header className="mb-12 md:mb-16">
            <h2>Frequently Asked Questions</h2>
          </header>

          <Accordion
            allowZeroExpanded={true}
            className="border-t border-solid border-primary-600/20"
          >
            {faqs.map((faq, i) => {
              return (
                <div key={i}>
                  <AccordionItem
                    className="border-b border-solid border-primary-600/20 pt-6 md:pt-7 pb-5 md:pb-6"
                    uuid={i}
                  >
                    <AccordionItemButton className="flex items-center justify-between focus:outline-none">
                      <p className="font-heading text-xl md:text-3xl font-bold mb-0">
                        {faq.question}
                      </p>
                      <AccordionItemState>
                        {(state) => {
                          const icon = state.expanded
                            ? "fa-chevron-up"
                            : "fa-chevron-down";
                          return (
                            <i
                              className={`far ${icon} text-lg text-primary-400 ml-4`}
                            ></i>
                          );
                        }}
                      </AccordionItemState>
                    </AccordionItemButton>
                    <AccordionItemPanel className="pt-6 pr-10 max-w-4xl animate-fadeIn">
                      <p className="mb-0">{faq.answer}</p>
                    </AccordionItemPanel>
                  </AccordionItem>
                </div>
              );
            })}
          </Accordion>
        </div>
      </section>

      <Testimonials />
      <Resources />
      <ValueProps />
      <About className="mb-0" />
      <CallToAction />
    </Layout>
  );
};

export const query = graphql`
  {
    openGraphImage: file(
      relativePath: { eq: "open-graph/facebook/Refinance.jpg" }
    ) {
      publicURL
    }
    twitterOpenGraphImage: file(
      relativePath: { eq: "open-graph/twitter/Refinance.jpg" }
    ) {
      publicURL
    }
    heroDesktop: file(relativePath: { eq: "3.0 Refinance/hero.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 680)
      }
    }
    calculateSavings: file(
      relativePath: { eq: "3.0 Refinance/calculate-savings.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 560)
      }
    }
  }
`;
export default Page;
